// Sound banks data

export const padBanks = {
  "heater": [
    { 
      id : "Q",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Heater-1.mp3",
      key : 81,
      description : "Heater 1"
    },
    { 
      id : "W",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Heater-2.mp3",
      key : 87,
      description : "Heater 2"
    },
    { 
      id : "E",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Heater-3.mp3",
      key : 69,
      description : "Heater 3"
    },
    { 
      id : "A",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Heater-4_1.mp3",
      key : 65,
      description : "Heater 4"
    },
    { 
      id : "S",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Heater-6.mp3",
      key : 83,
      description : "Clap"
    },
    { 
      id : "D",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Dsc_Oh.mp3",
      key : 68,
      description : "Open-HH"
    },
    { 
      id : "Z",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Kick_n_Hat.mp3",
      key : 90,
      description : "Kick-n'-Hat"
    },
    { 
      id : "X",
      url : "https://s3.amazonaws.com/freecodecamp/drums/RP4_KICK_1.mp3",
      key : 88,
      description : "Kick"
    },
    { 
      id : "C",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Cev_H2.mp3",
      key : 67,
      description : "Closed-HH"
    }
  ],
  "chord": [
    { 
      id : "Q",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Chord_1.mp3",
      key : 81,
      description : "Chord-1"
    },
    { 
      id : "W",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Chord_2.mp3",
      key : 87,
      description : "Chord-2"
    },
    { 
      id : "E",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Chord_3.mp3",
      key : 69,
      description : "Chord-3"
    },
    { 
      id : "A",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Give_us_a_light.mp3",
      key : 65,
      description : "Shaker"
    },
    { 
      id : "S",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Dry_Ohh.mp3",
      key : 83,
      description : "Open-HH"
    },
    { 
      id : "D",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Bld_H1.mp3",
      key : 68,
      description : "Closed-HH"
    },
    { 
      id : "Z",
      url : "https://s3.amazonaws.com/freecodecamp/drums/punchy_kick_1.mp3",
      key : 90,
      description : "Punchy-Kick"
    },
    { 
      id : "X",
      url : "https://s3.amazonaws.com/freecodecamp/drums/side_stick_1.mp3",
      key : 88,
      description : "Side-Stick"
    },
    { 
      id : "C",
      url : "https://s3.amazonaws.com/freecodecamp/drums/Brk_Snr.mp3",
      key : 67,
      description : "Snare"
    }
  ]
}